const errorFormattingMixin = {
  methods: {
    getErrorTitle(text) {
      const title = text.replaceAll('_', ' ')
      return this.toTitleCase(title)
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
  },
}

module.exports = errorFormattingMixin
