<template>
  <div class="relative mt-2 bg-white rounded shadow">
    <section v-if="!isLoaded" class="h-96">
      <loading :active="!isLoaded" :is-full-page="false" />
    </section>

    <content-section v-else-if="isLoaded || lockConfig">
      <oto-typography text="Basic" variant="one" />
      <div class="grid grid-cols-1 mt-5 md:grid-cols-2 md:gap-5">
        <div v-if="lockConfig.has_ble_key_updates" class="flex items-center">
          <text-input
            rules=""
            label="Scooter Key"
            :value="vehicle.scooter_extras.scooter_key || ''"
            :disabled="true"
            placeholder="Reload..."
          />
          <div class="flex items-center w-3/12">
            <!-- <anchor-button text="Edit" variant="green" class="pl-3" /> -->
            <anchor-button
              text="Get Key"
              variant="success-alt"
              class="pl-3"
              @click="handleFetchBleKey"
            />
          </div>
        </div>

        <div
          v-if="lockConfig.has_firmware_version_updates"
          class="flex items-center"
        >
          <text-input
            rules=""
            label="Firmware Version"
            :value="vehicle.scooter_extras.firmware_version"
            :disabled="true"
            placeholder="Reload..."
          />
          <div class="flex items-center w-8/12">
            <!-- <anchor-button text="Edit" variant="green" class="pl-3" /> -->
            <anchor-button
              text="Get Version"
              variant="success-alt"
              class="pl-3"
            />
          </div>
        </div>
      </div>

      <oto-typography
        text="Controller"
        variant="one"
        class="inline-block mt-10"
      />
      <div class="max-w-4xl">
        <div class="grid grid-cols-1 gap-8 mt-5 md:grid-cols-2">
          <template v-if="lockConfig.has_headlight_control">
            <toggle-box
              v-model="vehicle.control_flags.headlight"
              title="Headlight"
              subtitle="Always on headlight"
              onText="ON"
              offText="OFF"
              :onValue="true"
              :offValue="false"
              size="lg"
              :readonly="isEditable"
              @change="handleHeadlightUpdate"
            />
          </template>

          <template v-if="lockConfig.has_taillight_control">
            <toggle-box
              v-model="vehicle.control_flags.taillight"
              title="Taillight"
              subtitle="Always on taillight"
              onText="ON"
              offText="OFF"
              :onValue="true"
              :offValue="false"
              size="lg"
              :readonly="isEditable"
              @change="handleTaillightUpdate"
            />
          </template>

          <template v-if="lockConfig.has_throttle_control">
            <toggle-box
              v-model="vehicle.control_flags.throttle"
              title="Throttle"
              subtitle="Allow throttling"
              onText="ON"
              offText="OFF"
              :onValue="true"
              :offValue="false"
              size="lg"
              :readonly="isEditable"
              @change="handleThrottleUpdate"
            />
          </template>

          <template v-if="lockConfig.has_speed_mode_control">
            <toggle-box
              v-model="vehicle.control_flags.lock_speed_mode"
              title="Lock Speed Mode"
              subtitle="Control the speed mode"
              onText="ON"
              offText="OFF"
              :onValue="true"
              :offValue="false"
              size="lg"
              :readonly="isEditable"
              @change="handleLockSpeedModeUpdate"
            />
          </template>

          <template v-if="lockConfig.has_ring_alarm">
            <alarm-box
              title="Ring"
              subtitle="Trigger ring"
              size="lg"
              @click="handleAlarmClicked"
              :readonly="isEditable"
            />
          </template>

          <template v-if="lockConfig.has_unlock_battery_control">
            <alarm-box
              title="Unlock Battery"
              subtitle="Unlock battery compartment"
              size="lg"
              @click="handleBatteryUnlockClicked"
            />
          </template>

          <template v-if="lockConfig.has_restart_control">
            <alarm-box
              title="Restart"
              subtitle="Request IoT restart"
              size="lg"
              @click="handleRestartIotClicked"
            />
          </template>

          <template v-if="lockConfig.has_speed_limit_control">
            <OptionsTrackbar
              v-model="vehicle.scooter_extras.speed"
              :size="`lg`"
              :options="[
                {
                  title: 'Low',
                  subtitle: `${vehicle.scooter_extras.low_speed_limit} km/hour`,
                  speed: 'L',
                },
                {
                  title: 'Medium',
                  subtitle: `${vehicle.scooter_extras.medium_speed_limit} km/hour`,
                  speed: 'M',
                },
                {
                  title: 'High',
                  subtitle: `${vehicle.scooter_extras.high_speed_limit} km/hour`,
                  speed: 'H',
                },
              ]"
              :title-attribute="`title`"
              :subtitle-attribute="`subtitle`"
              :value-attribute="`speed`"
              :clicked-item-is-loading="optionsTrackbarIsLoading"
              @click="onClickOptionsTrackbar"
            />
          </template>
        </div>
      </div>

      <!-- <div class="max-w-4xl mt-16">
        <div class="flex items-center justify-between">
          <div>
            <oto-typography text="Speed Limit for Speed Mode" variant="one" />
          </div>
          <div>
            <oto-edit-button @click="handleEditing('speeds')" />
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5 md:grid-cols-3 md:gap-10">
          <text-input
            label="Low Speed Limit"
            v-model="speeds.low_speed_limit"
            :disabled="true"
          />
          <text-input
            label="Medium Speed Limit"
            v-model="speeds.medium_speed_limit"
            :disabled="true"
          />
          <text-input
            label="Maximum Speed Limit"
            v-model="speeds.max_speed_limit"
            :disabled="true"
          />
        </div>
      </div> -->

      <div class="max-w-4xl mt-16">
        <!-- <div class="flex items-center justify-between">
          <div>
            <oto-typography text="Location Update" variant="one" />
          </div>
          <div>
            <oto-edit-button @click="handleEditing('location')" />
          </div>
        </div> -->

        <div class="grid grid-cols-1 mt-5 md:grid-cols-3 md:gap-10">
          <text-input
            label="Location Update Frequency (On Ride)"
            v-model="getOnRideLocationUpdateFrequency"
            :disabled="true"
          />
          <text-input
            label="Location Update Frequency (Off Ride)"
            v-model="getOffRideLocationUpdateFrequency"
            :disabled="true"
          />
          <div class="hidden md:block"></div>
        </div>
      </div>
      <div class="max-w-4xl mt-16" v-if="isSegwayIOT">
        <div class="w-full">
          <!-- <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-cog text-22px text-oPurple"></i>
            <span class="pl-3">Maximum Speed Limit For Speed Mode</span>
          </div> -->
          <oto-typography
            text="Maximum Speed Limit For Speed Mode"
            variant="one"
          />
          <div :key="`speedlimit_${counter}`" class=" mt-3 pt-5 border-t-2">
            <template v-for="(item, itemIndex) in speedLimitSettings">
              <SmartAppInput
                :key="itemIndex"
                :rowId="`speedlimit_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :options="item.options"
                :isEdit="item.isEdit"
                :isSearchBox="item.isSearchBox"
                v-model="vehicle['scooter_extras'][`${item.variable}`]"
                @save="
                  onSetLimit(
                    `${item.mode}`,
                    `${vehicle['scooter_extras'][`${item.variable}`]}`
                  )
                "
              />
            </template>
          </div>
        </div>
      </div>
    </content-section>
  </div>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import OtoTypography from '@/components/ui/OtoTypography'
import ToggleBox from '@/components/form/ToggleBox.vue'
import AnchorButton from '@/components/form/AnchorButton'
import TextInput from '@/components/form/TextInput'
// import OtoEditButton from '@/components/ui/OtoEditButton'
import AlarmBox from '@/components/form/AlarmBox.vue'
import { VehicleConfig } from '@/config/VehicleConfig'
import { SocketConfig } from '@/config/SocketConfig'
import ReconnectingWebsocket from 'reconnectingwebsocket'
// import { openRWS, closeRWS } from '@/services'
import errorFormattingMixin from '@/mixins/errorFormattingMixin'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils'
export default {
  name: 'ViewVehicleConfiguration',
  mixins: [errorFormattingMixin],
  components: {
    ContentSection,
    OtoTypography,
    ToggleBox,
    TextInput,
    AnchorButton,
    // OtoEditButton,
    AlarmBox,
    OptionsTrackbar: () => import('@/components/form/OptionsTrackbar.vue'),
    SmartAppInput,
  },

  data() {
    return {
      isLoaded: false,
      counter: 0,
      details: null,
      lockConfig: null,
      currentSettingsGroup: null,
      vehicle: {
        control_flags: {
          headlight: false,
          taillight: false,
          throttle: false,
          lock_speed_mode: false,
        },
        scooter_extras: {
          firmware_version: null,
          scooter_key: null,
          speed: null,
          low_speed_limit: 0,
          medium_speed_limit: 0,
          high_speed_limit: 0,
        },
      },

      triggerAlerm: true,
      location: {
        on_ride_location_update_frequency: '7s',
        off_ride_location_update_frequency: '10s',
      },

      speed: 'L',
      optionsTrackbarIsLoading: false,

      sockListener: null,
      speedLimitSettings: [
        {
          type: 'richselect',
          label: 'Low Speed Limit (km/h)',
          placeholder: `e.g 6`,
          options: [
            { value: 6, text: 6 },
            { value: 7, text: 7 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 11, text: 11 },
            { value: 12, text: 12 },
            { value: 13, text: 13 },
            { value: 14, text: 14 },
            { value: 15, text: 15 },
            { value: 16, text: 16 },
            { value: 17, text: 17 },
            { value: 18, text: 18 },
            { value: 19, text: 19 },
            { value: 20, text: 20 },
            { value: 21, text: 21 },
            { value: 22, text: 22 },
            { value: 23, text: 23 },
            { value: 24, text: 24 },
            { value: 25, text: 25 },
          ],
          variable: 'low_speed_limit',
          mode: 'L',
          isEdit: false,
          isSearchBox: false,
        },
        {
          type: 'richselect',
          label: 'Medium Speed Limit (km/h)',
          placeholder: `e.g 15`,
          options: [
            { value: 6, text: 6 },
            { value: 7, text: 7 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 11, text: 11 },
            { value: 12, text: 12 },
            { value: 13, text: 13 },
            { value: 14, text: 14 },
            { value: 15, text: 15 },
            { value: 16, text: 16 },
            { value: 17, text: 17 },
            { value: 18, text: 18 },
            { value: 19, text: 19 },
            { value: 20, text: 20 },
            { value: 21, text: 21 },
            { value: 22, text: 22 },
            { value: 23, text: 23 },
            { value: 24, text: 24 },
            { value: 25, text: 25 },
          ],
          variable: 'medium_speed_limit',
          mode: 'M',
          isEdit: false,
          isSearchBox: false,
        },
        {
          type: 'richselect',
          label: 'High Speed Limit (km/h)',
          placeholder: `e.g 25`,
          options: [
            { value: 6, text: 6 },
            { value: 7, text: 7 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 11, text: 11 },
            { value: 12, text: 12 },
            { value: 13, text: 13 },
            { value: 14, text: 14 },
            { value: 15, text: 15 },
            { value: 16, text: 16 },
            { value: 17, text: 17 },
            { value: 18, text: 18 },
            { value: 19, text: 19 },
            { value: 20, text: 20 },
            { value: 21, text: 21 },
            { value: 22, text: 22 },
            { value: 23, text: 23 },
            { value: 24, text: 24 },
            { value: 25, text: 25 },
          ],
          variable: 'high_speed_limit',
          mode: 'H',
          isEdit: false,
          isSearchBox: false,
        },
      ],
    }
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'speedlimit') {
          // this.speedLimitSettings = this.speedLimitSettings.map((item) => ({
          //   ...item,
          //   isEdit: false,
          // }))
        }
      },
    },
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    isEditable() {
      if (this.$acl.canNotUpdate('Vehicles')) {
        return true
      }
      return this.$store.getters['vehicleDetails/getLockStatus']
    },
    isSegwayIOT() {
      if (
        this.$store.state.vehicleDetails.details.lock.iot_category.split(
          '_'
        )[0] === 'SEGWAY'
      ) {
        return true
      } else {
        return false
      }
    },
    getOnRideLocationUpdateFrequency() {
      if (
        this.$store.state.vehicleDetails.details.lock.iot_category ===
        'SEGWAY_MAXPRO'
      ) {
        return '10s'
      } else {
        return this.location.on_ride_location_update_frequency
      }
    },
    getOffRideLocationUpdateFrequency() {
      if (
        this.$store.state.vehicleDetails.details.lock.iot_category ===
        'SEGWAY_MAXPRO'
      ) {
        return '180s'
      } else {
        return this.location.off_ride_location_update_frequency
      }
    },
  },
  // created() {
  //   this.startVehicleUpdateListener()
  // },

  async mounted() {
    this.$nextTick(async () => {
      const store = this.$store.state.vehicleDetails
      let type = 'lockConfig'
      if (!store.details && !store.lockConfig) {
        type = 'all'
      }
      store.details && (this.vehicle = store.details)

      this.isLoaded = false
      await this.$store
        .dispatch('vehicleDetails/fetchData', {
          type,
          primaryKey: this.$route.params.id,
        })
        .then(() => {
          type === 'all' && (this.vehicle = store.details)
          this.lockConfig = this.$store.state.vehicleDetails.lockConfig
          this.vehicle.scooter_extras.low_speed_limit =
            store.details.scooter_extras.low_speed_limit
          this.vehicle.scooter_extras.medium_speed_limit =
            store.details.scooter_extras.medium_speed_limit
          this.vehicle.scooter_extras.high_speed_limit =
            store.details.scooter_extras.high_speed_limit
          this.isLoaded = true
        })

      this.$http.get()
    })

    // this.updateModel()

    // this.rws = await openRWS({
    //   channel: 'bikeNotifications',
    //   onMessage: function({ m }) {
    //     console.log({ x: m.data })
    //   },
    // })

    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'speedlimit') {
        // this.speedLimitSettings = this.speedLimitSettings.map(
        //   (item, itemIndex) => {
        //     if (id === `speedlimit_${itemIndex}`) {
        //       return {
        //         ...item,
        //         isEdit: true,
        //       }
        //     } else {
        //       return {
        //         ...item,
        //         isEdit: false,
        //       }
        //     }
        //   }
        // )
        console.log('speedlimitid', this.speedLimitSettings)
        this.currentSettingsGroup = 'speedlimit'
      }
    })
  },
  async beforeDestroy() {
    this.removeListener()
    // this.removeVehicleUpdateListener()
    // await closeRWS(this.rws)
  },
  methods: {
    async onSetLimit(mode, limit) {
      console.log('speed-limit', mode, limit)
      if (this.$acl.canUpdate('vehicles')) {
        const formData = new FormData()
        formData.append('mode', mode)
        formData.append('limit', limit)

        // this.optionsTrackbarIsLoading = true
        // this.startListener()
        await this.$http
          .post(
            `/dashboard/vehicles/${this.$route.params.id}/speed-limit/`,
            formData
          )
          .then((res) => {
            // this.speedLimitSettings = this.speedLimitSettings.map((item) => ({
            //   ...item,
            //   isEdit: false,
            // }))

            this.counter++
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Speed Mode',
                text:
                  res.data?.detail ||
                  'Speed limit update request has been sent',
              },
              2000
            )
          })
          .catch((err) => {
            this.$log.error(err)
            this.$notify({
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            })
          })
          .finally(() => {
            this.optionsTrackbarIsLoading = false
          })
      }
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeNotifications +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(4003, 'oto system closed the socket')
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      // validate payload
      console.log('Incoming --> ', message)

      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_noti') return

      // handle payload consumption
      let data = payload.data
      var updatedData = false
      console.log('Recieved Payload...', data, payload.n_type)

      if ('headlight' in data) {
        this.vehicle.control_flags.headlight = data.headlight
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Headlight',
          text: `Headlight turned ${data.headlight ? 'on' : 'off'}`,
        })
        updatedData = true
      }

      if ('taillight' in data) {
        this.vehicle.control_flags.taillight = data.taillight
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Taillight',
          text: `Tail light turned ${data.taillight ? 'on' : 'off'}`,
        })
        updatedData = true
      }

      if ('throttle' in data) {
        this.vehicle.control_flags.throttle = data.throttle
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Throttling',
          text: `Throttling ${data.throttle ? 'enabled' : 'disabled'}`,
        })
        updatedData = true
      }

      if ('lock_speed_mode' in data) {
        this.vehicle.control_flags.lock_speed_mode = data.lock_speed_mode
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Lock Speed Mode',
          text: `Lock Speed Mode ${
            data.lock_speed_mode ? 'enabled' : 'disabled'
          }`,
        })
        updatedData = true
      }
      if ('scooter_key' in data) {
        this.vehicle.scooter_extras.scooter_key = data.scooter_key
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Scooter Key',
          text: `Scooter key received`,
        })
        updatedData = true
      }

      if ('firmware_version' in data) {
        this.vehicle.scooter_extras.firmware_version = data.firmware_version
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Firmware version',
          text: `Firmware version received`,
        })
        updatedData = true
      }

      if ('speed' in data) {
        this.$store.commit('vehicleDetails/updateScooterExtras', {
          key: 'speed',
          value: data.speed,
        })
        this.vehicle.scooter_extras.speed = data.speed
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Speed ',
          text: `Speed settings updated`,
        })
        updatedData = true
      }

      if ('battery_unlocked' in data) {
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Battery Unlocked',
          text: `Battery compartment has been unlocked`,
        })
        updatedData = true
      }

      if ('iot_restarted' in data) {
        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Vehicle IOT Restarted',
          text: `IOT has been restarted`,
        })
        updatedData = true
      }

      //close socket
      if (updatedData) this.removeListener()
    },

    //vehicle update
    startVehicleUpdateListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection bikeUpdates channel...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection bikeUpdates channel...')
      }
      this.sockListener.onmessage = this.onMessageVehicleUpdateHandler
    },
    removeVehicleUpdateListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket to refresh stream'
        )
        this.sockListener = null
      }
    },
    onMessageVehicleUpdateHandler(message) {
      console.log('Incoming update', message, JSON.parse(message.data))
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_updates') return

      const sRes = payload.data
      console.log('Recieved Payload...V', sRes['id'] === this.id, sRes['id'])

      let bikeId = sRes['id']
      if (!bikeId) return
      if (this.id !== bikeId) return
      this.vehicle.control_flags.headlight = sRes.control_flags.headlight
      this.vehicle.control_flags.taillight = sRes.control_flags.taillight
      this.vehicle.control_flags.throttle = sRes.control_flags.throttle
      this.vehicle.control_flags.lock_speed_mode =
        sRes.control_flags.lock_speed_mode

      console.log('Incoming update current', message, JSON.parse(message.data))
    },

    handleEditing(type) {
      // TODO: update the route name
      let location = type === 'speeds' ? 'VehicleSettings' : 'VehicleSettings'
      this.$router.push({
        name: location,
      })
    },

    async handleAlarmClicked() {
      let url = VehicleConfig.api.ringAlarm(this.id)
      await this.$http
        .post(url)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Alarm',
              text: 'Ringing alarm...',
            },
            10000
          )
        })
        .catch((err) => {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async handleBatteryUnlockClicked() {
      this.startListener()
      let url = VehicleConfig.api.unlockBattery(this.id)
      await this.$http
        .post(url)
        .then(() => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Unlocked',
            text: 'Battery Unlock request sent...',
          })
        })
        .catch((err) => {
          this.removeListener()
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },
    async handleRestartIotClicked() {
      this.startListener()
      let url = VehicleConfig.api.restartIot(this.id)
      await this.$http
        .post(url)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Restart requested',
              text: 'IOT restart request sent...',
            },
            10000
          )
        })
        .catch((err) => {
          this.removeListener()
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },
    async handleHeadlightUpdate() {
      console.log({ x: this.vehicle.control_flags.headlight })
      let data = new FormData()
      data.append('headlight', this.vehicle.control_flags.headlight)
      console.log('HEADLIGHT STATE: ' + this.vehicle.control_flags.headlight)
      await this.handleFlagUpdate(data)
    },
    async handleTaillightUpdate() {
      let data = new FormData()
      data.append('taillight', this.vehicle.control_flags.taillight)
      await this.handleFlagUpdate(data)
    },
    async handleThrottleUpdate() {
      console.log({ x: this.vehicle.control_flags.throttle })

      let data = new FormData()
      data.append('throttle', this.vehicle.control_flags.throttle)
      await this.handleFlagUpdate(data)
    },
    async handleLockSpeedModeUpdate() {
      let data = new FormData()
      data.append('lock_speed_mode', this.vehicle.control_flags.lock_speed_mode)
      await this.handleFlagUpdate(data)
    },
    async handleFlagUpdate(data) {
      let url = VehicleConfig.api.updateControlFLags(this.id)
      console.log({ end: url })

      // data.forEach((value, key) => {
      //   console.log(`${key} : ${value}`)
      // })
      this.startListener()
      // console.log(data)
      await this.$http
        .patch(url, data)
        .then((response) => {
          console.log('Server Received ...')
          console.log(response.data)
          // todo: save data on store as well
          // this.vehicle.control_flags.taillight = response.data.taillight
          // this.vehicle.control_flags.lock_speed_mode =
          //   response.data.lock_speed_mode
          // this.vehicle.control_flags.throttle = response.data.throttle

          // this.$store.dispatch("vehicledetail/saveData", );
          // this.$notify({
          //     group: "generic",
          //     type: "success",
          //     title: "Status updated",
          //     text: "Status updated"
          // })
        })
        .catch((err) => {
          this.removeListener()
          console.log({ err })
          this.$log.error(err)
          if (err.response.data.message) {
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `${this.getErrorTitle(err.response.data.type)} [${
                  err.response.status
                }]`,
                text: err.response.data.message,
              },
              10000
            )
          } else {
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Error [${err.response.status}]`,
                text: err.response.data,
              },
              10000
            )
          }
        })
    },
    async handleFetchBleKey() {
      let url = VehicleConfig.api.reload.bluetoothKey(this.id)
      console.log({ end: url })
      this.startListener()
      await this.$http
        .get(url)
        .then((response) => {
          console.log(response.data)
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Request Sent',
            text: 'Request Sent. Should Update momentarily.',
          })
        })
        .catch((err) => {
          this.$log.error(err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async onClickOptionsTrackbar(e) {
      if (this.$acl.canUpdate('vehicles')) {
        this.$log.debug(e)
        const formData = new FormData()
        formData.append('mode', e.speed)

        this.optionsTrackbarIsLoading = true
        this.startListener()
        await this.$http
          .post(
            `/dashboard/vehicles/${this.$route.params.id}/speed-mode/`,
            formData
          )
          .then((res) => {
            this.$log.debug(res.data)

            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Speed Mode',
                text:
                  res.data?.detail || 'Speed mode update request has been sent',
              },
              2000
            )
          })
          .catch((err) => {
            this.$log.error(err)
            this.$notify({
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            })
          })
          .finally(() => {
            this.optionsTrackbarIsLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
