<template>
  <div @click="onToggleClick">
    <div class="wrapper wrapper-size-sm" :class="getWrapperClass">
      <div class="label-area">
        <slot name="labels">
          <div class="title">{{ title }}</div>
          <div class="subtitle">{{ subtitle }}</div>
        </slot>
      </div>
      <div class="toggle-area">
        <div>
          <t-toggle
            v-model="model"
            :readonly="readonly"
            :variant="`success`"
            :checked="getChecked"
            :value="onValue"
            :uncheckedValue="offValue"
            @input="onInput"
          />
        </div>
        <div
          v-if="onText && offText"
          class="status-text"
          :class="getStatusTextClass"
        >
          {{ getStatusText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import InputLabel from "@/components/form/InputLabel";

export default {
  name: 'ToggleBox',
  components: {
    // InputLabel
  },
  props: [
    'title',
    'subtitle',
    'onText',
    'onValue',
    'offText',
    'offValue',
    'value',
    'size',
    'readonly',
  ],
  data() {
    return {
      model: null,
    }
  },

  computed: {
    getStatusText() {
      return this.model === this.onValue ? this.onText : this.offText
    },
    getWrapperClass() {
      let sizes = ['base', 'md', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'

      let status = this.model === this.onValue ? 'wrapper-on' : 'wrapper-off'

      return 'wrapper-size-' + size + ' ' + status
    },
    getStatusTextClass() {
      return this.model === this.onValue ? 'text-on' : 'text-off'
    },
    getChecked() {
      // console.log(this.model, this.onValue);
      return this.model === this.onValue ? true : false
    },
  },

  created() {
    // console.log("cCh", this.checked);
    // console.log("cVal", this.value);
    // console.log("cModel", this.model);
    // console.log("cCheck", this.getChecked);
    // console.log("ce", this.checked);
  },

  watch: {
    value: {
      immediate: true,
      deep: false,
      handler() {
        // console.log("tbWatcher==", this.value);
        this.model = this.value
      },
    },
    model: {
      immediate: false,
      deep: false,
      handler(updated, old) {
        if (updated === old) return

        this.$emit('change', updated)
      },
    },
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
    onToggleClick() {
      this.$emit('click-box')
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  transition: all 0.5s ease-in-out;
  border-width: 1px;
  border-style: solid;
  &.wrapper-on {
    border-color: #09aa35;
    background-color: #f9fff8;
  }
  &.wrapper-off {
    border-color: #e2e8f0;
    background-color: #f7f7f7;
  }
  &.wrapper-size-base {
    height: 58px;
  }

  &.wrapper-size-md {
    height: 72px;
  }

  &.wrapper-size-lg {
    height: 110px;
  }

  // label area
  & .label-area {
    & .title {
      color: #2e2e39;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-size: 14px;
    }
    & .subtitle {
      padding-top: 4px;
      font-size: 13px;
      font-weight: normal;
      @apply text-gray-600;
    }
  }
  // toggle area
  & .toggle-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .status-text {
      width: 2.5rem;
      // manually centerign text
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: bold;
      padding-left: 5px;
      transition: all 0.5s ease-in-out;
    }
    & .text-on {
      color: #09aa35;
    }
    & .text-off {
      color: #838391;
    }
  }
}
</style>
